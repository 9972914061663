import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Card = ({ post }) => {
  // Check if post exists
  if (post && Object.keys(post).length === 0 && post.constructor === Object) {
    return (
      <article className="module__posts-card card">
        <p className="card__title is-h6">- Post does not exist</p>
      </article>
    );
  }

  let postType = post.__typename.replace("WPGraphQL_", "").toLowerCase();
  let title = post.title.replace(/&#038;/g, "&");
  if (postType === "testimonial") {
    let videoId = "";
    if (post.testimonialFields.video) {
      let regExp =
        /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?/;
      let match = post.testimonialFields.video.match(regExp);
      if (match && match[6].length === 11) {
        videoId = match[6];
      }
    }
    return (
      <article className={`module__posts-card card ${postType}`}>
        <>
          {post.testimonialFields.video ? (
            <div className={"card__excerpt"}>
              <div className="iframe-scaler">
                {videoId !== "" ? (
                  <lite-youtube
                    videoid={videoId}
                    playlabel={`Play: ${title}`}
                  ></lite-youtube>
                ) : (
                  <iframe
                    width="560"
                    height="315"
                    src={post.testimonialFields.video}
                    frameBorder="0"
                    allowFullScreen=""
                    title={post.title}
                    loading="lazy"
                  />
                )}
              </div>
            </div>
          ) : (
            <>
              <div
                className="card__excerpt"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
              <p className="card__title is-h6">- {title}</p>
              {post.featuredImage ? (
                <GatsbyImage
                  image={
                    post.featuredImage.node.imageFile.childImageSharp
                      .gatsbyImageData
                  }
                  className={"card__image avatar"}
                  alt={post.featuredImage.node.altText}
                  title={post.featuredImage.node.title}
                  width={150}
                  height={150}
                  imgStyle={{ objectPosition: "center center" }}
                  objectPosition={"center center"}
                />
              ) : null}
            </>
          )}
        </>
      </article>
    );
  } else if (postType === "beforeafter") {
    let before = post.beforeAfterFields.before;
    let after = post.beforeAfterFields.after;

    return (
      <article className={`module__posts-card card ${postType}`}>
        <div className="row">
          <div className="col-xs-6">
            <img
              className="card__image"
              src={before.sourceUrl}
              alt={before.altText}
              title={before.title}
              srcSet={before.srcSet}
              sizes={before.sizes}
              loading={"lazy"}
            />
            <p className="card__title is-h6">Before</p>
          </div>
          <div className="col-xs-6">
            <img
              className="card__image"
              src={after.sourceUrl}
              alt={after.altText}
              title={after.title}
              srcSet={after.srcSet}
              sizes={after.sizes}
              loading={"lazy"}
            />
            <p className="card__title is-h6">After</p>
          </div>
        </div>
      </article>
    );
  }
};

export default Card;
